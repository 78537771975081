// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import users from '@src/views/user/store'
import companies from '@src/views/company/store'
import robots from '@src/views/robot/store'
import environments from '@src/views/environment/store'
import workspaces from '@src/views/workspace/store'
import items from '@src/views/item/store'
import tasks from '@src/views/task/store'
import tasktypes from '@src/views/tasktype/store'
import robotmodels from '@src/views/robotmodels/store'
import softwareUpdates from '@src/views/software-updates/store'
import serverlogs from '@src/views/serverlog/store'
import mapview from '@src/views/mapview/store'
import serviceschedule from '@src/views/serviceschedule/store'
import tasktemplate from '@src/views/tasktemplate/store'
import chargingStation from '@src/views/chargingStation/store'


const rootReducer = {
  auth,
  navbar,
  layout,
  users,
  companies,
  robots,
  environments,
  workspaces,
  items,
  tasks,
  tasktypes,
  robotmodels,
  softwareUpdates,
  serverlogs,
  mapview,
  serviceschedule,
  tasktemplate,
  chargingStation
}

export default rootReducer
