// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { formatDateTime } from '@utils'

import ServerLog from '../../../services/ServerLog'

export const serverlogsList = createAsyncThunk('serverlogs/list', async (params) => {
  const response = await ServerLog.list({params})
  return {
    data: response.data
  }
})

export const serverlogsSlice = createSlice({
  name: 'serverlogs',
  initialState: {
    data: [],
    loading: false,
    total: 0
  },
  // reducers: {
  //   clearSelection: (state) => {
  //     state.selectedTaskType = null
  //   }
  // },
  extraReducers: builder => {
    builder
      .addCase(serverlogsList.fulfilled, (state, action) => {
        state.data = action.payload.data.data.map((item) => ({
          ...item,
          dateTime: item.timestamp
        }))
        state.total = action.payload.data.count
        state.loading = false
      })
      .addCase(serverlogsList.pending, (state) => {
        state.loading = true
      })
      .addCase(serverlogsList.rejected, (state) => {
        state.loading = false
      })
  }
})
export const {
  clearSelection
} = serverlogsSlice.actions

export default serverlogsSlice.reducer
