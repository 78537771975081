// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import SoftwareUpdates from "../../../services/SoftwareUpdates"

export const softwareUpdatesList = createAsyncThunk('software-updates/list', async (params) => {
  const response = await SoftwareUpdates.list({params})
  return {
    params,
    data: response.data.softwareUpdates,
    total: response.data.total
  }
})

export const createSoftwareUpdate = createAsyncThunk('software-updates/create', async (params, { dispatch, getState, rejectWithValue }) => {
  try {
    const res = await SoftwareUpdates.create(params)
    await dispatch(softwareUpdatesList(getState().softwareUpdates.params))
    return res.data
  } catch (err) {
    console.error('createModel error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateSoftwareUpdate = createAsyncThunk('software-updates/update', async (softwareUpdate, {dispatch, getState}) => {
  try {
    const {id, ...params} = softwareUpdate
    const res = await new SoftwareUpdates(id).update(params)
    await dispatch(softwareUpdatesList(getState().softwareUpdates?.params))
    return res.data
  } catch (err) {
    return console.log(err)
  }
})

export const deleteSoftwareUpdate = createAsyncThunk('software-updates', async ({id}, {dispatch, getState}) => {
  try {
    await new SoftwareUpdates(id).delete()
    await dispatch(softwareUpdatesList(getState().softwareUpdates?.params))
    // return res.data
  } catch (err) {
    return console.log(err)
  }
})


export const softwareUpdatesSlice = createSlice({
  name: 'softwareUpdates',
  initialState: {
    data: [],
    total: 0,
    params: {},
    loading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedTaskType = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(softwareUpdatesList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(softwareUpdatesList.pending, (state) => {
        state.loading = true
      })
      .addCase(softwareUpdatesList.rejected, (state) => {
        state.loading = false
      })
  }
})
export const {
  clearSelection
} = softwareUpdatesSlice.actions

export default softwareUpdatesSlice.reducer
