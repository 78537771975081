// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'
import axios from "axios"

export default class SoftwareUpdates extends BaseService {
  static collection = 'software-updates'

  static list(params) {
    return axios.get(this.constructUrl(), params)
  }

  delete(params) {
    return axios.delete(this.constructUrl(), params)
  }
}
